
import "../styles/main.scss";

import scrollEvents from './parts/scrollEvents';
import scrollToEvents from './parts/scrollToEvents';
import heroScroll from './parts/heroScroll';
import menu from './parts/menu';
import searchform from './parts/searchform';
import pageNav from './parts/pageNav';
import modalJobs from './parts/modalJobs';
import './parts/bootstrap';

//import sliders from './parts/_sliders.js';

//sliders.init();

scrollEvents.init();
heroScroll.init();
menu.init();
searchform.init();
pageNav.init();
scrollToEvents.init();
modalJobs.init();