
import { Collapse as Collapse } from 'bootstrap';

if(document.querySelectorAll('.collapse:not(.accordion-collapse)').length > 0){
    Array.from(document.querySelectorAll('.collapse'))
        .forEach(collapsetNode => new Collapse(collapsetNode, {toggle: false}));

    var collapseGroup = document.querySelector('.collapse-group');

    collapseGroup.addEventListener('show.bs.collapse', function (e) {
        
        if(collapseGroup.querySelector('button:not(.collapsed')){
            collapseGroup.querySelector('button:not(.collapsed').classList.add('collapsed');
        }

        if(collapseGroup.querySelector('.collapse.show')){
            collapseGroup.querySelector('.collapse.show').classList.remove('show');
        };
    });
}


(function () {
  'use strict';

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated');
      }, false);
    });
})();