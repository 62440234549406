//import $ from "jquery";

const $ = window.jQuery;
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);
import menu from './menu';

let offset = 100;

const scrollToElem = (elem) => {
    
    if (elem != '#') {
        let whereToScroll = $(elem).offset().top - offset;
        gsap.to(window, { duration: 1.4, scrollTo: whereToScroll, ease: "power4.inOut" });
        history.pushState({}, "", elem);
    }
}

const init = () => {

    /*
        Scroll to top
    */
    $('#scroll-to-top').on('click', function(e){
        e.preventDefault();
        gsap.to(window, { duration: 1.5, scrollTo: 0, ease: "power4.inOut" });
    });

    /*
        Scroll to link
    */
    $("#main a[href^='#']").each(function(){
        $(this).on('click', function(event){
            event.preventDefault();

            if($(this).hasClass('block-page-nav__item')){
                offset = document.querySelector(".block-page-nav__inner").clientHeight;
            }

            var targetHref = $(this).attr('href');
            scrollToElem(targetHref);
        });
    });

    $(".main-menu a[href*='#']").each(function(){
        $(this).on('click', function(event){
            
            let targetHref = event.currentTarget.hash;

            if(document.querySelector(targetHref)){
                event.preventDefault();

                async function scrollToSection(elem) {
                    await menu.close();
                    scrollToElem(elem);
                }

                scrollToSection(targetHref);
            }
        });
    });

    let currentHash = location.hash;
    location.hash = '';

    if(currentHash){

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);

        setTimeout(() => {
            scrollToElem(currentHash);
        }, 1000);
    }
}

export default { init };
