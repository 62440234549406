
const init = () => {

    var sdlastScrollTop = 0;
    var sdScrollOffset = 100;
    let body = document.querySelector('body');
    let topCheckpoint = 200;

    const intersectionObserverDefaultOptions = {
        rootMargin: '0px 0px -20% 0px',
        threshold: 0,
    };

    const checkScroll = () => {
        
        var st = window.pageYOffset || document.documentElement.scrollTop;

        // Scroll Direction
        if (st > (sdlastScrollTop + sdScrollOffset)) {
            body.classList.remove('isScroll--up');
            body.classList.add('isScroll--down');
            sdlastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

            if(body.classList.contains('subMenuIsOpen')){
                body.classList.remove('subMenuIsOpen');
                //$('#menu-main-menu').find('.openSubmenu').removeClass('openSubmenu');
                //$('#menu-main-menu .submenu-accordion-container').hide();
            }


            body.classList.remove('showHeader');
            
        } else if (st < (sdlastScrollTop - sdScrollOffset)) {
            body.classList.remove('isScroll--down');
            body.classList.add('isScroll--up');
            sdlastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }

        // Top Checkpoint
        if (window.pageYOffset > topCheckpoint) {
            body.classList.add('topCheckpoint');
        } else {
            body.classList.remove('topCheckpoint');
        }
    }

    window.addEventListener("scroll", function() {
        
        checkScroll();

    }, false);

    checkScroll();


    let hero = document.querySelector('.block-hero-scroll') ? document.querySelector('.block-hero-scroll') : document.querySelector('.block-hero-small');

    if (hero) {

        const blockTextObserver = new window.IntersectionObserver(
            (entries) => {
                
                if(entries[0].isIntersecting){
                    body.classList.add('hero-is-visible');
                }else{
                    body.classList.remove('hero-is-visible');
                }
            },
            intersectionObserverDefaultOptions
        );

        blockTextObserver.observe(hero);
    }

};

export default { init };