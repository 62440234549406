
import { gsap, TimelineMax, Power3 } from "gsap";


let body, menuMainTl = null;

const close = () => {

    return new Promise((resolve, reject) => {
        body.classList.remove("open-menu");

        menuMainTl.timeScale( 1.5 );
        menuMainTl.reverse();

        setTimeout(resolve, 800);
    });
};

const init = () => {

	// Menu mobile
    body = document.querySelector('body');
	const menuToggles = document.querySelectorAll(".menuToggle");
    
	menuMainTl = new TimelineMax({ paused: true });

	menuMainTl
    .to('.main-menu', 0.1, { autoAlpha: 1 }, 0)
    .to('.main-menu__bg', 0.8, { autoAlpha: 1, height: '100%', ease: Power3.easeInOut }, 0.1)
    .staggerFrom('.main-menu .nav > li', 0.8, { autoAlpha: 0, y: '-30%', ease: Power3.easeOut }, 0.05, 0.8 )
    .from('.main-menu__side--right-line', 0.8, { 'height': '0%', ease: Power3.easeOut }, 1 )
    .from('.main-menu__side--right-line-hori', 0.8, { autoAlpha: 0, 'y': '-20px', ease: Power3.easeOut }, 1 );

    /*
	var menuMainV2Tl = new TimelineMax({ paused: true });

	menuMainV2Tl
    .to('.main-menu-v2', 0.1, { autoAlpha: 1 }, 0)
    .to('.main-menu-v2__bg', 0.8, { autoAlpha: 1, height: '100%', ease: Power3.easeInOut }, 0.1)
    .to('.main-menu-v2__bg--2', 1.2, { autoAlpha: 1, y: '0%', ease: Power3.easeOut })
    .staggerFrom('.main-menu-v2 .nav > li', 0.8, { autoAlpha: 0, y: '-30%', ease: Power3.easeOut }, 0.05, 0.8 );
    */

    /*
    // Chiusura del menu mobile al click anchor
    $('.menu-mobile a[href*="#"]:not([href^="#"])').on('click', function(){
        close();
    });
    */

	// Mobile menu open/close
    menuToggles.forEach(menuToggle => {

        menuToggle.addEventListener('click', () => {

            if(body.classList.contains("open-menu")){

                close();

            }else{
                body.classList.add("open-menu");
                
                menuMainTl.timeScale( 1 );
                menuMainTl.play();
                //menuMainV2Tl.timeScale( 1 );
                //menuMainV2Tl.play();
            }
	    });
    });

    /*
	window.addEventListener('resize', function(){
		if(window.innerWidth > 1024 && body.classList.contains("open-menu")){
			close();
		}
	});
    */
};

export default { init, close };
