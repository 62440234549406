const $ = window.jQuery;

const init = () => {

  $('.change-title').on('click', function(e){
    const newtitle = $(this).attr('id').toUpperCase().replace('-', ' '); 
    $('#changethis').html(newtitle);
    $('#job-title').val($(this).attr('id'));
  });
}

export default { init };
