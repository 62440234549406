
import { gsap, TimelineMax, Power3 } from "gsap";

let body, searchMainTl = null;

const close = () => {

    return new Promise((resolve, reject) => {
        body.classList.remove("open-search");

        searchMainTl.timeScale( 1.5 );
        searchMainTl.reverse();

        setTimeout(resolve, 800);
    });
};

const init = () => {

	// Menu mobile
    body = document.querySelector('body');
	const searchToggles = document.querySelectorAll(".searchToggle");
    const formInput = document.querySelectorAll("#s");


    formInput.forEach(input => {
        input.placeholder = 'Cerca nel sito...';
    });
    
	searchMainTl = new TimelineMax({ 
        paused: true, 
        onComplete: () => { 
            document.querySelector(".search-fullpage #s").focus();
        } 
    });

	searchMainTl
    .to('.search-fullpage', 0.1, { autoAlpha: 1 }, 0)
    .to('.search-fullpage__bg', 0.8, { autoAlpha: 1, height: '100%', ease: Power3.easeInOut }, 0.1)
    .from('.search-fullpage form', 0.8, { autoAlpha: 0, y: '-30%', ease: Power3.easeOut }, '-=0.5' )
    .from('.search-fullpage .btn-close--white', 0.4, { autoAlpha: 0, y: '-30%', ease: Power3.easeOut }, '-=0.5' );

	// Mobile search open/close
    searchToggles.forEach(menuToggle => {

        menuToggle.addEventListener('click', () => {

            if(body.classList.contains("open-search")){

                close();

            }else{
                body.classList.add("open-search");
                
                searchMainTl.timeScale( 1 );
                searchMainTl.play();
            }
	    });
    });

    document.addEventListener('keydown', function(event){
	    if(event.key === "Escape"){
             if(body.classList.contains("open-search")){
                close();
            }
        }
    });
};

export default { init, close };
