
import { gsap,  } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const init = () => {

    let scroll_tfip_tl;

    if(document.querySelector('.block-hero-scroll')){

        let hero = document.querySelector('.block-hero-scroll');
        let hero_bg = document.querySelector('.block-hero-scroll__bg');

        scroll_tfip_tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".block-hero-scroll",
                start: "top top",
                pin: true,
                //markers: true,
                scrubber: true,
                scrub: 1
            }
        });


        scroll_tfip_tl
            .to('.block-hero-scroll__content', { y: '-10%', scale: 0.8, duration: 0.1 })
            //.to('.block-hero-scroll__content h1', { y: '-10%', scale: 0.8, duration: 0.1 }, 0)
            .to('.block-hero-scroll__item', { autoAlpha: 1, y: '-20%', duration: 0.2 })
            .to('.block-hero-scroll__overlay', { 'opacity': 0.9 }, 0);

        /*
        for (let i = 1; i <= (titles.length -1); i++){

            let currentTitle = titles[i-1];
            let nextTitle = titles[i];

            let currentText = texts[i-1];
            let nextText = texts[i];

            scroll_tfip_tl.addLabel(`item${i}`)
                .to(currentTitle, { autoAlpha: 0, y: '-20%', duration: 0.2 }, `item${i}`)
                .from(nextTitle, { autoAlpha: 0, y: '20%', duration: 0.2 }, `item${i}`)
                .to(currentText, { autoAlpha: 0, y: '-20%', duration: 0.2 }, `item${i}`)
                .from(nextText, { autoAlpha: 0, y: '20%', duration: 0.2 }, `item${i}`);
        }
        */

        let hW = hero.clientWidth;
        let hH = hero.clientHeight;

        hero.addEventListener('mousemove', (e) => {
            let mX = e.pageX;
            let mY = e.pageY;

            let mPercentX = Math.round((((0.5/hW)*mX)-(0.5/2))*100)/100;
            let mPercentY = Math.round((((0.5/hH)*mY)-(0.5/2))*100)/100;

            hero_bg.style.transform = `translate(${mPercentX}%, ${mPercentY}%) scale(1.05)`;
        });
    }
};

export default { init };