

const init = () => {

	// Menu mobile
	const block = document.querySelector('.block-page-nav');
	const blockInner = document.querySelector(".block-page-nav__inner");

    if(!block || !blockInner) return;

    const blockItems = block.querySelectorAll('.block-page-nav__item');
    let h = 0;

    const checkH = () => {
        
        h = blockInner.clientHeight;
        block.style.height = `${h}px`;
    };

    checkH();

    window.addEventListener('resize', () => {
        setTimeout(checkH, 200);
    });




    let sections = document.querySelectorAll('.pageNavSections section');

    const checkSection = (section) => {

        let sectRect = section.getBoundingClientRect();

        if(sectRect.top > 0 && sectRect.top < 200){

            let currentSection = section.getAttribute('id');

            Array.from(blockItems)
                .forEach(item => item.classList.remove('active'));
	        block.querySelector(`.block-page-nav__item[href="#${currentSection}"`).classList.add('active');
        }
    }

    window.addEventListener("scroll", function() {
        let elRect = block.getBoundingClientRect();

        if(elRect.top <= 0){
            block.classList.add('fix');
        }else{
            block.classList.remove('fix');
        }

        if(elRect.top <= -20){
            block.classList.add('minimize');
        }else{
            block.classList.remove('minimize');
        }


        Array.from(sections)
            .forEach(section => checkSection(section));
    });
};

export default { init };
